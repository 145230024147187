import { Button } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CustomIcon } from 'components/common/custom-icon';
import { DialogButton } from 'components/common/dialogs/button';
import { IntercomSnapshotUpdater } from 'components/common/intercom-snapshot-updater';
import { MachineConnectionDialog } from 'components/machine/dialogs/connection';
import { MachineControlDialog } from 'components/machine/dialogs/control';
import { MachineInspectionDialog } from 'components/machine/dialogs/inspection';
import { R2FStatusDialog } from 'components/machine/dialogs/r2f-status';
import { RealignMachineDialog } from 'components/machine/dialogs/realign-machine';
import { IntercomListener } from 'components/main/listeners/intercom';
import { NotificationListener } from 'components/main/listeners/notification';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IHittersContext, getEmptyStats } from 'contexts/hitters.context';
import { ISessionEventsContext } from 'contexts/session-events.context';
import { addDays, isFuture, lightFormat, parseISO } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { CustomIconPath } from 'enums/custom.enums';
import { MachineMode } from 'enums/machine.enums';
import { t } from 'i18next';
import { INotificationButton } from 'interfaces/i-notification';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { MPH_TO_KPH } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMachineActiveModelID } from 'lib_ts/classes/ms.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ModelStatus } from 'lib_ts/enums/machine-models.enums';
import {
  BallStatusMsgType,
  DefaultVideoID,
  SfxName,
  WsMsgType,
} from 'lib_ts/enums/machine-msg.enum';
import {
  BallType,
  FireOption,
  FiringMode,
  MS_LIMITS,
  TrainingMode,
} from 'lib_ts/enums/machine.enums';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { BuildPriority, PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IHitterStats } from 'lib_ts/interfaces/i-hitter';
import {
  DEFAULT_CONTEXT_MACHINE,
  IMachine,
  IMachineModelDictionary,
} from 'lib_ts/interfaces/i-machine';
import {
  CalibrateProc,
  IBallStatusMsg,
  ICalibrateRequestMsg,
  IMachineStateMsg,
  IMachineStatusMsg,
  IProcessQueryResponseMsg,
  IProcessStatus,
  IProjectorAdjustmentMsg,
  IQueueMsg,
  IReadyMsg,
  IRulerMsg,
  ITwoFactorMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import {
  DEFAULT_MACHINE_STATE,
  IMachineState,
} from 'lib_ts/interfaces/i-machine-state';
import {
  IFireEvent,
  IMSSnapshotData,
  IMSTargetEventData,
  ISessionEvent,
} from 'lib_ts/interfaces/i-session-event';
import { IVideo } from 'lib_ts/interfaces/i-video';
import { IFireMsg } from 'lib_ts/interfaces/machine-msg/i-fire';
import { IProjectorSfxMsg } from 'lib_ts/interfaces/machine-msg/i-projector-sfx';
import { IPitchPreviewOverlayMsg } from 'lib_ts/interfaces/machine-msg/i-projector-text-overlay';
import {
  ISpecialMstargetMsg,
  SpecialMsPosition,
} from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import {
  IBallDetailsError,
  IPitch,
  IPitchList,
  IPlateLoc,
} from 'lib_ts/interfaces/pitches';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import { UserMachineModelsService } from 'services/machine-models.service';
import { MachinesService } from 'services/machines.service';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import { WebSocketService } from 'services/web-socket.service';
import slugify from 'slugify';
import { v4 } from 'uuid';
import { InboxContext } from './inbox';

const CONTEXT_NAME = 'MachineContext';

const SUPPRESS_LOW_LIGHT_WARNING = true;
const SUPPRESS_REDUNDANT_SNAPSHOTS = true;

const MIN_WAIT_BETWEEN_FIRE_MS = 2_000;

const DAYS_TO_WARN_HW_CHANGE = 1;

const MOUND_HEIGHT_FT = 10 / 12;

export enum MachineDialogMode {
  Disconnected,
  Inspect,
  R2F,
  RequestControl,
  Realign,
}

/** if the machine is using any ball type from this array, rapid mode will always be engaged */
const RAPID_BALL_TYPES: BallType[] = [BallType.Smash, BallType.StingFreeJugs];

/** if the machine is using any ball type from this array, rapsodo validation will always be skipped */
const NO_RAPSODO_BALL_TYPES: BallType[] = [
  BallType.Smash,
  BallType.StingFreeJugs,
];

/** how long before the recently fired pitch is cleared */
const RECENTLY_FIRED_DURATION = 3_000;

export interface IMachineContext {
  lastPitchID?: string;

  lastMS?: IMachineState;
  readonly setLastMS: (ms: IMachineState) => void;

  lastMSHash?: string;
  readonly resetMSHash: () => void;
  // does the target need to be sent, or is it already the last thing sent
  readonly requiresSend: (target?: IMachineStateMsg) => boolean;
  // was the last thing sent something that could (eventually) become R2F (e.g. not a screensaver)
  readonly attemptingR2F: () => boolean;

  lastBallCount?: number;
  lastR2F?: IReadyMsg;

  /** overlay ids as of last request */
  lastOverlayIDs?: string[];

  machine: IMachine;
  activeModel?: IMachineModel;
  readonly getModelName: (id: string) => string;

  loading: boolean;
  firing: boolean;
  calibrated: boolean;
  canCalibrate: boolean;

  autoFire: boolean;
  readonly setAutoFire: (value: boolean) => void;

  fireOptions: FireOption[];
  readonly addFireOption: (flag: FireOption) => void;
  readonly removeFireOption: (flag: FireOption) => void;

  /** true when calibration starts, false after completion */
  calibrating: boolean;

  connected: boolean;
  busy: boolean;
  activeUser: string;
  process_statuses: IProcessStatus[];
  readonly setProcessData: (proc: IProcessQueryResponseMsg) => void;

  /** returns true if the current user is connected + machine is not busy, else shows warning and returns false */
  readonly checkActive: (silently?: boolean) => boolean;

  readonly sendPitchPreview: (config: {
    trigger: string;
    prev?: IPitch;
    current: IPitch;
    next?: IPitch;
  }) => void;

  /** result indicates whether a msg was sent or not */
  readonly sendTarget: (config: {
    source: string;
    msMsg: IMachineStateMsg;
    pitch: Partial<IPitch>;
    plate?: IPlateLoc;
    list?: IPitchList;
    hitter_id?: string;
    video?: IVideo;
    // skips checks and validations
    force?: boolean;
    trigger?: string;
  }) => Promise<{ success: boolean; hash?: string }>;

  /** bypasses most safety validations */
  readonly sendRawTarget: (
    msg: IMachineStateMsg,
    source: string,
    silently: boolean
  ) => Promise<boolean>;

  /** indicate whether a calibration is required based on success/failure of calibration response */
  readonly setCalibrated: (value: boolean) => void;
  readonly setCanCalibrate: (value: boolean) => void;

  readonly setFiring: (value: boolean) => void;

  /** from machine-status, for connected, busy, and activeUser */
  readonly setStatus: (data: IMachineStatusMsg) => void;

  /** respond to waiting user, whether their request for control is accepted/rejected */
  readonly sendControlResponse: (msg: IQueueMsg) => void;

  readonly update: (machine: Partial<IMachine>) => Promise<boolean>;

  readonly dropball: (notify: boolean, source: string) => void;

  readonly specialMstarget: (position: SpecialMsPosition) => void;

  readonly calibrate: (procs: CalibrateProc[], source: string) => void;

  readonly send2FA: (source: string, msg: ITwoFactorMsg) => void;

  /** ask the machine which overlays are currently active on projector */
  readonly requestOverlayIDs: (source: string) => void;

  /** called by listener, record whatever was caught */
  readonly setOverlayIDs: (values: string[]) => void;

  readonly sendProcessReset: (source: string, proc: string) => void;

  readonly sendProcessQuery: (source: string) => void;

  readonly sendProcessKill: (source: string, proc: string) => void;

  // aka: soft reboot
  readonly restartOS: (source: string) => void;

  // aka: system restart
  readonly restartArc: (source: string) => void;

  readonly fire: (config: {
    pitch: Partial<IPitch>;
    mode: FiringMode;
    trigger: string;
    training: boolean;
    training_mode?: TrainingMode;
    hitter_id?: string;
    tags?: string;
  }) => Promise<boolean>;

  readonly toggleRuler: (source: string, msg: IRulerMsg) => void;

  readonly adjustKeystone: (
    source: string,
    msg: IProjectorAdjustmentMsg
  ) => void;

  /** temporarily stores the pitch that was recently fired, self-clears after a delay
   * use-case: pitch list can highlight the pitch (if its _id matches) until it's cleared
   */
  recentlyFiredPitch?: Partial<IPitch>;

  readonly setDialog: (mode: MachineDialogMode | undefined) => void;

  readonly getSpecialMode: () => MachineMode | undefined;
  readonly setSpecialMode: (mode?: MachineMode) => void;

  readonly activateModel: (config: {
    modelID: string;
    modelKey: string;
    silently?: boolean;
  }) => Promise<boolean>;

  readonly onEndTraining: () => void;

  readonly playSound: (effect: SfxName) => void;

  readonly getAutoFireButton: (config: {
    // i.e. value after toggling
    beforeToggleFn?: (newValue: boolean) => void;
    className?: string;
    as?: 'dialog-button';
  }) => ReactNode;
}

const DEFAULT: IMachineContext = {
  machine: DEFAULT_CONTEXT_MACHINE,
  getModelName: () => '',
  loading: false,

  setLastMS: () => console.debug('not init'),
  resetMSHash: () => console.debug('not init'),
  requiresSend: () => false,
  attemptingR2F: () => false,

  fireOptions: [FireOption.SkipRapsodoValidation],
  addFireOption: () => console.debug('not init'),
  removeFireOption: () => console.debug('not init'),

  calibrated: false,
  canCalibrate: false,
  calibrating: false,
  firing: false,

  autoFire: false,
  setAutoFire: () => console.debug('not init'),

  connected: false,
  busy: false,
  activeUser: 'none',
  process_statuses: [],
  setProcessData: () => console.debug('not init'),

  checkActive: () => false,
  setCalibrated: () => console.debug('not init'),
  setCanCalibrate: () => console.debug('not init'),
  setFiring: () => console.debug('not init'),
  setStatus: () => console.debug('not init'),

  sendPitchPreview: () => console.debug('not init'),
  sendTarget: () => new Promise(() => ({ success: false })),
  sendRawTarget: () => new Promise(() => false),
  update: () => new Promise(() => false),

  dropball: () => console.debug('not init'),
  specialMstarget: () => console.debug('not init'),
  calibrate: () => console.debug('not init'),
  send2FA: () => console.debug('not init'),
  requestOverlayIDs: () => console.debug('not init'),
  setOverlayIDs: () => console.debug('not init'),
  sendProcessReset: () => console.debug('not init'),
  sendProcessQuery: () => console.debug('not init'),
  sendProcessKill: () => console.debug('not init'),
  restartOS: () => console.debug('not init'),
  restartArc: () => console.debug('not init'),
  fire: () => new Promise(() => false),

  sendControlResponse: () => console.debug('not init'),

  toggleRuler: () => console.debug('not init'),

  adjustKeystone: () => console.debug('not init'),

  setDialog: () => console.debug('not init'),

  getSpecialMode: () => undefined,
  setSpecialMode: () => console.debug('not init'),

  activateModel: () => new Promise(() => false),

  onEndTraining: () => console.debug('not init'),
  playSound: () => console.debug('not init'),

  getAutoFireButton: () => <></>,
};

export const MachineContext = createContext(DEFAULT);

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sessionsCx: ISessionEventsContext;
  hittersCx: IHittersContext;
  children: ReactNode;
}

export const MachineProvider: FC<IProps> = (props) => {
  const [_ballStatusToast, _setBallStatusToast] = useState(false);
  const [_lastMachineEvent, _setLastMachineEvent] = useState(new Date());

  const [_lastBallDate, _setLastBallDate] = useState(new Date());
  const [_lastBallCount, _setLastBallCount] = useState(DEFAULT.lastBallCount);

  const [_lastR2F, _setLastR2F] = useState(DEFAULT.lastR2F);

  const [_hwWarned, _setHwWarned] = useState(false);
  const [_machine, _setMachine] = useState(DEFAULT.machine);
  const [_activeModel, _setActiveModel] = useState(DEFAULT.activeModel);
  const [_modelDict, _setModelDict] = useState<IMachineModelDictionary>({});
  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_fireOptions, _setFireOptions] = useState(DEFAULT.fireOptions);

  /** from machine-status, whether machine is connected to the server or not */
  const [_connected, _setConnected] = useState(DEFAULT.connected);
  const [_busy, _setBusy] = useState(DEFAULT.busy);
  const [_activeUser, _setActiveUser] = useState(DEFAULT.activeUser);

  const [_calibrated, _setCalibrated] = useState(DEFAULT.calibrated);
  const [_canCalibrate, _setCanCalibrate] = useState(DEFAULT.canCalibrate);
  const [_calibrating, _setCalibrating] = useState(DEFAULT.calibrating);
  const [_firing, _setFiring] = useState(DEFAULT.firing);
  const [_autoFire, _setAutoFire] = useState(DEFAULT.autoFire);
  const [_process_statuses, _setProcessStatuses] = useState(
    DEFAULT.process_statuses
  );

  /** updated whenever a ms is sent to the machine, used to track what was last sent
   * e.g. to start screensaver without moving anything else */
  const [_lastMS, _setLastMS] = useState(DEFAULT_MACHINE_STATE);
  const [_lastPitchID, _setLastPitchID] = useState(DEFAULT.lastPitchID);
  const [_lastFireDate, _setLastFireDate] = useState<number | undefined>();

  /** hash of lastMS used for quickly detecting differences */
  const [_lastMSHash, _setLastMSHash] = useState<string | undefined>();
  const [_lastOverlayIDs, _setLastOverlayIDs] = useState(
    DEFAULT.lastOverlayIDs
  );

  const [_recentlyFiredPitch, _setRecentlyFiredPitch] = useState(
    DEFAULT.recentlyFiredPitch
  );

  const [dialogDisconnected, setDialogDisconnected] = useState<
    number | undefined
  >();
  const [dialogInspect, setDialogInspect] = useState<number | undefined>();
  const [dialogR2F, setDialogR2F] = useState<number | undefined>();
  const [dialogRequestControl, setDialogRequestControl] = useState<
    number | undefined
  >();
  const [dialogRealign, setDialogRealign] = useState<number | undefined>();

  const [_specialMode, _setSpecialMode] = useState<MachineMode | undefined>();

  // takes on the value of _lastMSHash whenever an Intercom URL is about to be generated, to determine if it can skip
  const [_intercomHash, _setIntercomHash] = useState<string | undefined>();
  const [_intercomURL, _setIntercomURL] = useState<string | undefined>();

  const _safeSetMachine = async (machine: IMachine, calibrated?: boolean) => {
    if (calibrated !== undefined) {
      _setCalibrated(calibrated);
    }

    if (
      !_hwWarned &&
      machine.last_hardware_changed &&
      isFuture(
        addDays(parseISO(machine.last_hardware_changed), DAYS_TO_WARN_HW_CHANGE)
      )
    ) {
      // hardware change happened within the last day and warning has not been shown yet
      NotifyHelper.info({
        message_md: `There was a hardware change for \`${
          machine.machineID
        }\` on ${lightFormat(
          parseISO(machine.last_hardware_changed),
          'yyyy-MM-dd'
        )}. Shot data collected before this change will no longer be usable on this machine.`,
      });
      _setHwWarned(true);
    }

    _setMachine(machine);
    const modelID = getMachineActiveModelID(machine);

    if (!modelID) {
      NotifyHelper.error({
        message_md: `${machine.machineID} has no active model. Please contact support.`,
      });
      return;
    }

    const model =
      await UserMachineModelsService.getInstance().getModel(modelID);
    _setActiveModel(model);
  };

  // this needs to be this way so that other actions which specifically require update to finish successfully can properly await
  const _update = async (payload: Partial<IMachine>): Promise<boolean> => {
    try {
      if (
        payload.plate_distance !== undefined &&
        (payload.plate_distance > MS_LIMITS.PLATE_DISTANCE.MAX ||
          payload.plate_distance < MS_LIMITS.PLATE_DISTANCE.MIN)
      ) {
        NotifyHelper.warning({
          message_md: `Plate distance should be between ${MS_LIMITS.PLATE_DISTANCE.MIN}-${MS_LIMITS.PLATE_DISTANCE.MAX} ft.`,
        });
        return false;
      }

      _setLoading(true);

      const result = await MachinesService.getInstance()
        .update(payload)
        .finally(() => _setLoading(false));

      if (!result) {
        throw new Error(
          `Empty result received from server while updating machine`
        );
      }

      _safeSetMachine(result);
      return true;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was a server error while updating your machine. See console for details.',
      });

      return false;
    }
  };

  const _checkActiveUser = (silently?: boolean) => {
    if (!_connected) {
      if (!silently) {
        NotifyHelper.warning({
          message_md: `You must be connected to ${
            _machine ? _machine.machineID : 'a machine'
          } to perform this action.`,
          buttons: [
            {
              label: 'Reconnect',
              onClick: () => props.authCx.reconnectWS(),
              dismissAfterClick: true,
            },
          ],
        });
      }
      return false;
    }

    if (_busy) {
      if (!silently) {
        NotifyHelper.warning({
          message_md: `You must be the active user on ${
            _machine ? _machine.machineID : 'a machine'
          } to perform this action.`,
          buttons: [
            {
              label: 'Request Control',
              onClick: () => setDialogRequestControl(Date.now()),
              dismissAfterClick: true,
            },
          ],
        });
      }
      return false;
    }

    return true;
  };

  /** union of options based on user settings + required by other factors */
  const _getFireOptions = (config: { ball_type: BallType }): FireOption[] => {
    const unique: Set<FireOption> = new Set(_fireOptions);

    /** example of adding training flags */
    if (
      !_machine.enable_raspodo_validation ||
      NO_RAPSODO_BALL_TYPES.includes(config.ball_type)
    ) {
      unique.add(FireOption.SkipRapsodoValidation);
    }

    if (!_machine.enable_continuous_training) {
      unique.add(FireOption.DisableTrainFromFire);
    }

    return Array.from(unique);
  };

  const _checkFinishedFiring = (silently?: boolean) => {
    if (_firing) {
      if (!silently) {
        NotifyHelper.warning({
          message_md: `Please wait for ${_machine.machineID} to finish firing before trying again.`,
        });
      }

      return false;
    }

    return true;
  };

  /** helper that also keeps lastMS in sync */
  const _sendMS = (msg: IMachineStateMsg, source: string) => {
    WebSocketService.send(WsMsgType.U2S_MsTarget, msg, source);
    _setLastMS(msg);

    NotifyHelper.debug(
      {
        message_md: `S2M triggered from ${source}!`,
        buttons: [
          {
            label: 'Save MS',
            dismissAfterClick: true,
            onClick: () => {
              const safeSource = slugify(source, {
                strict: true,
                lower: true,
              });

              const filename = [
                _machine.machineID,
                safeSource,
                MachineHelper.getMSHash('matching', msg),
              ].join('-');

              MiscHelper.saveAs(
                new Blob([JSON.stringify(msg, null, 2)]),
                `${filename}.json`
              );
            },
          },
        ],
      },
      props.cookiesCx
    );
  };

  const _specialMsTarget = (position: SpecialMsPosition) => {
    if (!_checkActiveUser(true)) {
      return;
    }

    if (!_checkFinishedFiring(true)) {
      return;
    }

    const data: ISpecialMstargetMsg = {
      position: position,
    };

    WebSocketService.send(WsMsgType.U2M_SpecialMsTarget, data, CONTEXT_NAME);
    _setLastMSHash(undefined);
  };

  const _handleBallStatus = (event: CustomEvent) => {
    const data: IBallStatusMsg = event.detail;

    // todo: deprecate this once all machines update FW to include new logic
    const anyData = data as any;
    if (typeof anyData.status === 'boolean') {
      if (anyData.status) {
        data.ball_count = 0;
      } else {
        data.ball_count = 1;
      }
    }

    _setLastBallCount(data.ball_count);
    _setLastBallDate(new Date());

    if (_specialMode === 'empty-carousel') {
      return;
    }

    if (data.type !== BallStatusMsgType.AfterDropball) {
      return;
    }

    if (data.ball_count !== 1) {
      // for safety
      _setAutoFire(false);

      SessionEventsService.postEvent({
        category: 'machine',
        tags: 'automation',
        data: {
          event: `${CONTEXT_NAME} automatically disabled auto-fire because of ball_count`,
          auto: false,
          ball_count: data.ball_count,
        },
      });

      NotifyHelper.warning({
        message_md: `Drop ball failed.`,
      });
      return;
    }

    if (data.already_present) {
      NotifyHelper.success({ message_md: 'Ball is already present.' });
      return;
    }

    // fallback
    NotifyHelper.success({ message_md: 'Drop ball was successful!' });
  };

  const _handleReadyToFire = (event: CustomEvent) => {
    const data: IReadyMsg = event.detail;

    // if all ready, then assume there's 1 ball loaded
    if (data.status) {
      _setLastBallCount(1);
      _setLastBallDate(new Date());
    }

    _setLastR2F(data);
  };

  const _dropball = (notify: boolean, source: string) => {
    if (!_checkActiveUser(!notify)) {
      return;
    }

    // for safety
    if (_specialMode !== 'empty-carousel') {
      _setAutoFire(false);
    }

    MachineContextHelper.sendDropBall({
      machineID: _machine.machineID,
      source: source,
    });
  };

  const state: IMachineContext = {
    machine: _machine,
    activeModel: _activeModel,
    getModelName: (id) => {
      return _modelDict[id] ?? 'Unknown';
    },

    lastPitchID: _lastPitchID,

    lastMS: _lastMS,
    setLastMS: (ms) => _setLastMS(ms),

    lastMSHash: _lastMSHash,
    resetMSHash: () => _setLastMSHash(undefined),

    setDialog: (value) => {
      switch (value) {
        case MachineDialogMode.Disconnected: {
          setDialogDisconnected(Date.now());
          break;
        }
        case MachineDialogMode.Inspect: {
          setDialogInspect(Date.now());
          break;
        }
        case MachineDialogMode.R2F: {
          setDialogR2F(Date.now());
          break;
        }
        case MachineDialogMode.Realign: {
          setDialogRealign(Date.now());
          break;
        }
        case MachineDialogMode.RequestControl: {
          setDialogRequestControl(Date.now());
          break;
        }
        default: {
          break;
        }
      }
    },

    requiresSend: (target) => {
      if (!_lastMSHash) {
        return true;
      }

      if (!target) {
        return true;
      }

      return MiscHelper.hashify(target) !== _lastMSHash;
    },
    attemptingR2F: () => {
      // if any wheel speed is non-zero, it is relevant to care about R2F
      // screensaver should cause this to return false
      return ![_lastMS.w1, _lastMS.w2, _lastMS.w3].every((w) => w === 0);
    },

    lastBallCount: _lastBallCount,

    lastOverlayIDs: _lastOverlayIDs,

    fireOptions: _fireOptions,
    addFireOption: (flag) => {
      if (!_fireOptions.includes(flag)) {
        _setFireOptions([..._fireOptions, flag]);
      }
    },

    removeFireOption: (flag) => {
      if (_fireOptions.includes(flag)) {
        _setFireOptions([..._fireOptions.filter((f) => f !== flag)]);
      }
    },

    calibrated: _calibrated,
    canCalibrate: _canCalibrate,
    calibrating: _calibrating,
    firing: _firing,

    autoFire: _autoFire,
    setAutoFire: (value) => {
      if (!_checkActiveUser(true)) {
        return;
      }

      _setAutoFire(value);
    },

    loading: _loading,
    connected: _connected,
    busy: _busy,
    activeUser: _activeUser,
    process_statuses: _process_statuses,
    setProcessData: (procQueryResponse: IProcessQueryResponseMsg) => {
      const processStatus = procQueryResponse.status;
      if (processStatus === true) {
        _setProcessStatuses(procQueryResponse.processes);
      }
    },

    setStatus: (data) => {
      const prevActive = _checkActiveUser(true);
      const prevConnected = _connected;

      const isActive = data.active === props.authCx.current.session;

      _setConnected(data.connected);
      _setBusy(data.connected && !isActive);
      _setActiveUser(data.userName);
      _setLastMachineEvent(new Date());

      _setCalibrated(data.calibrated);
      _setCanCalibrate(data.can_calibrate);

      if (!data.connected) {
        // for safety
        _setAutoFire(false);

        if (!prevConnected) {
          // avoid repeatedly notifying user
          return;
        }

        if (isActive) {
          NotifyHelper.warning({
            message_md: `\`${props.authCx.current.machineID}\` was disconnected from the server.`,
          });
        }
        return;
      }

      if (prevConnected && prevActive) {
        // avoid repeatedly notifying user as long as they remain connected + active
        return;
      }

      if (isActive) {
        _setLastMSHash(undefined);

        NotifyHelper.success({
          message_md: `You are now active on \`${props.authCx.current.machineID}\`.`,
        });

        // check if machine needs calibration
        if (!data.calibrated && data.can_calibrate) {
          NotifyHelper.warning({
            message_md:
              'Please realign your machine before continuing your session.',
            delay_ms: 0,
            buttons: [
              {
                label: 'Realign Machine',
                dismissAfterClick: true,
                onClick: () => setDialogRealign(Date.now()),
              },
            ],
          });
        }
        return;
      }

      if (data.waiting.includes(props.authCx.current.session)) {
        NotifyHelper.warning({
          message_md: `You are now waiting for \`${props.authCx.current.machineID}\`.`,
        });
        return;
      }
    },

    sendControlResponse: (msg) => {
      if (_checkActiveUser()) {
        WebSocketService.send(
          WsMsgType.Misc_ControlResponse,
          msg,
          'machine context'
        );
        NotifyHelper.info({
          message_md: `Response sent to \`${msg.waiting_user}\`.`,
        });
      }
    },

    checkActive: (silently) => _checkActiveUser(silently),

    specialMstarget: _specialMsTarget,

    dropball: _dropball,

    update: _update,

    recentlyFiredPitch: _recentlyFiredPitch,

    fire: async (config) => {
      if (!_checkActiveUser()) {
        return false;
      }

      if (
        _lastFireDate !== undefined &&
        performance.now() - _lastFireDate < MIN_WAIT_BETWEEN_FIRE_MS
      ) {
        return false;
      }

      const fireEvent: Partial<IFireEvent> = {
        category: 'machine',
        tags: 'fire',
        data: {
          mode: config.mode,

          ball_type: _machine.ball_type,
          pitch_id: config.pitch._id,
          pitch_type: config.pitch.type ?? PitchType.None,

          training: config.training,
          training_mode: config.training_mode,

          in_game: props.authCx.gameStatus === GameStatus.InProgress,

          trigger: config.trigger,
          options: _getFireOptions({ ball_type: _machine.ball_type }),
        },
      };

      if (fireEvent.data) {
        if (config.hitter_id) {
          /** update hitter stats */
          const stats: IHitterStats =
            props.hittersCx.stats.find(
              (s) => s.hitter_id === config.hitter_id
            ) ?? getEmptyStats(config.hitter_id);

          await props.hittersCx.upsertStats(config.hitter_id, {
            pitches: stats.pitches + 1,
          });

          /** assemble and attach extended hitter object to fire event  */
          fireEvent.data.hitterExt = props.hittersCx.getHitterExt(
            config.hitter_id
          );
        }

        if (config.tags) {
          const tags = ArrayHelper.unique(
            config.tags
              .toUpperCase()
              .split(',')
              .map((t) => t.trim())
          );

          if (tags.length > 0) {
            fireEvent.data.tags = tags;
          }
        }
      }

      const response = await SessionEventsService.postEvent(fireEvent);
      if (!response.success) {
        if (response.error === 'disconnected') {
          NotifyHelper.warning({
            message_md:
              'There was a problem processing your request. Your connection has been reset. Please try again.',
          });
          props.authCx.reconnectWS();
        }

        return false;
      }

      const data: IFireMsg = {
        fire_id: (response.data as ISessionEvent)._id,
      };

      await WebSocketService.send(WsMsgType.U2S_Fire, data, config.trigger);

      /** track fired shots for session */
      props.sessionsCx.increaseFired();

      /** take note that the pitch was recently fired */
      _setRecentlyFiredPitch(config.pitch);
      _setLastFireDate(performance.now());

      return true;
    },

    send2FA: (source, msg) => {
      WebSocketService.send(WsMsgType.S2M_TwoFa, msg, source);
    },

    requestOverlayIDs: (source) => {
      if (_checkActiveUser(true)) {
        WebSocketService.send(WsMsgType.U2S_Overlays, {}, source);
      }
    },

    setOverlayIDs: (values) => {
      _setLastOverlayIDs(values);
    },

    sendProcessReset: (source, proc) => {
      if (_checkActiveUser()) {
        WebSocketService.send(WsMsgType.Process_Reset, proc, source);
      }
    },

    sendProcessQuery: (source) => {
      if (_checkActiveUser()) {
        NotifyHelper.success({
          message_md: `Querying \`${_machine.machineID}\` for running processes...`,
        });
        WebSocketService.send(WsMsgType.Process_Query, {}, source);
      }
    },

    sendProcessKill: (source, proc) => {
      if (_checkActiveUser()) {
        WebSocketService.send(WsMsgType.Process_Kill, proc, source);
      }
    },

    restartOS: (source) => {
      if (_checkActiveUser()) {
        WebSocketService.send(WsMsgType.Process_SoftReboot, {}, source);
      }
    },

    restartArc: (source) => {
      if (_checkActiveUser()) {
        WebSocketService.send(WsMsgType.Process_SystemRestart, {}, source);
      }
    },

    calibrate: (procs, source) => {
      if (_checkActiveUser()) {
        // only wait for calibration completion if there are non-projector procs being calibrated
        if (
          procs.filter((p) => {
            return ![
              CalibrateProc.ProjectorOutline,
              CalibrateProc.ProjectorRulers,
            ].includes(p);
          }).length > 0
        ) {
          _setCalibrating(true);
        }

        SessionEventsService.postEvent({
          category: 'machine',
          tags: 'calibrate',
        }).then((response) => {
          if (!response.success) {
            _setCalibrating(false);
            return;
          }

          const message: ICalibrateRequestMsg = {
            procs: procs,
          };
          WebSocketService.send(WsMsgType.U2S_Calibrate, message, source);
        });

        // NotifyHelper.info({ message_md: `Calibration started, this will take approximately 1 minute. Please wait...` });
      }
    },

    setCalibrated: (value) => {
      _setCalibrating(false);
      _setCalibrated(value);
    },

    setCanCalibrate: (value) => {
      _setCanCalibrate(value);
    },

    setFiring: (value) => {
      _setFiring(value);
    },

    sendRawTarget: async (msg, source, silently) => {
      try {
        if (!_checkActiveUser(silently)) {
          return false;
        }

        if (!_checkFinishedFiring(silently)) {
          return false;
        }

        if (!silently) {
          NotifyHelper.info({
            message_md: 'Sending raw mstarget to machine...',
          });
        }

        _setLastMSHash(MiscHelper.hashify(msg));
        _sendMS(msg, source);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    sendPitchPreview: (config) => {
      const pitchToText = (pitch: IPitch): string => {
        const DISPLAY_DECIMALS = 0;
        const MIN_SPEED_LENGTH = '100'.length;

        const output: string[] = [];

        if (pitch.type && pitch.type !== PitchType.None) {
          output.push(pitch.type);
        }

        const speedMPH = BallHelper.getSpeed(pitch.bs);
        if (_machine.isMetric) {
          const speedKPH = speedMPH * MPH_TO_KPH;
          output.push(
            `${speedKPH
              .toFixed(DISPLAY_DECIMALS)
              .padStart(MIN_SPEED_LENGTH)} KPH`
          );
        } else {
          output.push(
            `${speedMPH
              .toFixed(DISPLAY_DECIMALS)
              .padStart(MIN_SPEED_LENGTH)} MPH`
          );
        }

        return output.join(' ');
      };

      const msg: IPitchPreviewOverlayMsg = {
        trigger: config.trigger,
        clear: false,
        lines: [pitchToText(config.current)],
        rich_lines: [],
      };

      if (config.prev) {
        msg.rich_lines.push({
          text: pitchToText(config.prev),
          color: 'grey',
          active: false,
        });
      }

      msg.rich_lines.push({
        text: pitchToText(config.current),
        color: 'white',
        active: true,
      });

      if (config.next) {
        msg.rich_lines.push({
          text: pitchToText(config.next),
          color: 'grey',
          active: false,
        });
      }

      WebSocketService.send(WsMsgType.S2M_PitchPreview, msg, CONTEXT_NAME);
    },

    sendTarget: async (config) => {
      try {
        if (!_checkActiveUser()) {
          throw new Error('Not active user');
        }

        if (!_checkFinishedFiring()) {
          throw new Error('Not finished firing');
        }

        // if provided, ensure video would not crash the projector
        if (config.video) {
          if (config.video.MoundPixelY <= config.video.ReleasePixelY) {
            NotifyHelper.warning({
              message_md: `Video mound pixel Y (${config.video.MoundPixelY}) must be greater than release pixel Y (${config.video.ReleasePixelY}).`,
            });
            throw new Error('Invalid video mound pixel Y');
          }

          if (config.video.ReleaseHeight <= MOUND_HEIGHT_FT) {
            NotifyHelper.warning({
              message_md: `Video release height (${
                config.video.ReleaseHeight
              } ft) must be greater than mound height (${MOUND_HEIGHT_FT.toFixed(
                1
              )} ft).`,
            });
            throw new Error('Invalid video release height');
          }

          const CHECK_VIDEO_FRAME_AND_TIME = false;
          if (CHECK_VIDEO_FRAME_AND_TIME) {
            if (
              config.video.ReleaseFrame === undefined ||
              config.video.ReleaseFrame < 1 ||
              config.video.ReleaseFrame > config.video.n_frames
            ) {
              NotifyHelper.warning({
                message_md: `Video has an invalid release frame (${config.video.ReleaseFrame}).`,
              });
              throw new Error('Invalid video release frame');
            }

            if (
              config.video.ReleaseTime === undefined ||
              config.video.ReleaseTime < 0 ||
              config.video.ReleaseTime > config.video.ffmpeg.duration.seconds
            ) {
              NotifyHelper.warning({
                message_md: `Video has an invalid release time (${config.video.ReleaseTime}).`,
              });
              throw new Error('Invalid video release time');
            }
          }
        }

        const safeMsg: IMachineStateMsg = {
          ...config.msMsg,

          rapid:
            // e.g. from auto-fire from settings
            config.msMsg.rapid ||
            _machine.enable_rapid_mode ||
            RAPID_BALL_TYPES.includes(_machine.ball_type),

          force: config.force,
        };

        if (!config.pitch._id) {
          // NotifyHelper.warning({ message_md: 'Sent pitch has no ID.', });
          config.pitch._id = `no-id-${v4()}`;

          console.warn({
            event: `${CONTEXT_NAME}: generated a temporary _id while sending a pitch without _id`,
            pitch: config.pitch,
          });
        }

        if (!config.pitch.bs) {
          NotifyHelper.error({
            message_md: 'Cannot send a pitch without a ball state.',
          });

          throw new Error('Cannot send a pitch without a ball state');
        }

        if (!config.pitch.traj) {
          NotifyHelper.error({
            message_md: 'Cannot send a pitch without a trajectory.',
          });

          throw new Error('Cannot send a pitch without a trajectory');
        }

        if (config.video) {
          safeMsg.video_uuid = config.video._id;

          const videoErrors = VideoHelper.getErrors(config.video);
          if (videoErrors.length > 0) {
            NotifyHelper.error({
              message_md: [
                'Cannot send a pitch using a video with invalid metadata:',
                videoErrors
                  .filter((_, i) => i < 5)
                  .map((e) => ` - ${e}`)
                  .join('\n'),
              ].join('\n\n'),
              delay_ms: 0,
            });

            throw new Error('Cannot send a pitch without valid metadata');
          }
        }

        const sessionData: IMSTargetEventData = {
          pitch_id: config.pitch._id,

          list: config.list ? config.list.name ?? '' : '',
          pitch_type: config.pitch.type ?? PitchType.None,
          pitch_title: config.pitch.name ?? '',
          pitcher: config.video ? config.video.PitcherFullName ?? '' : '',
          video_title: config.video ? config.video.VideoTitle ?? '' : '',
          build_priority: config.pitch.priority ?? BuildPriority.Spins,

          bs: config.pitch.bs,
          ms: safeMsg,
          traj: config.pitch.traj,

          plate: config.plate,

          breaks: config.pitch.breaks,

          hitterExt: config.hitter_id
            ? props.hittersCx.getHitterExt(config.hitter_id)
            : undefined,
        };

        /** validation stuff here */
        if (!_calibrated) {
          NotifyHelper.warning({
            message_md:
              'Calibration required. Please calibrate machine first and try again.',
          });
          throw new Error('Machine is not calibrated');
        }

        if (!config.force) {
          const errors: IBallDetailsError[] = MachineHelper.getSendTargetErrors(
            {
              bs: config.pitch.bs,
              traj: config.pitch.traj,
              ms: config.msMsg,
              plate_distance: _machine.plate_distance,
            }
          );

          /** if one or more errors came up, log them all but don't proceed with sending to machine */
          if (errors.length > 0) {
            sessionData.errors = errors;

            SessionEventsService.postEvent({
              category: 'machine',
              tags: 'mstarget,errors',
              data: sessionData,
            });

            const fixableErrors = errors.filter(
              (m) => m.fix?.autoFixMsFn !== undefined
            );

            const nonFixableErrors = errors.filter(
              (m) => m.fix?.autoFixMsFn === undefined
            );

            console.error({
              event: `${CONTEXT_NAME}: mstarget validation results`,
              fixable: fixableErrors.map((m) => m.msg),
              nonFixable: nonFixableErrors.map((m) => m.msg),
            });

            if (nonFixableErrors.length > 0) {
              NotifyHelper.error({
                message_md: `Cannot send pitch: ${nonFixableErrors[0].msg} (${
                  nonFixableErrors.length - 1
                } other errors)`,
              });

              throw new Error('Cannot send pitch with validation errors');
            }

            fixableErrors.forEach((m) => {
              if (m.fix?.autoFixMsFn) {
                config.msMsg = {
                  ...config.msMsg,
                  ...m.fix.autoFixMsFn(config.msMsg),
                };
              }
            });
          }
        }

        /** everything okay, send the ms */
        const response = await SessionEventsService.postEvent({
          category: 'machine',
          tags: 'mstarget,sent',
          trigger: config.trigger,
          data: sessionData,
        });

        if (!response.success) {
          if (response.error === 'disconnected') {
            NotifyHelper.warning({
              message_md:
                'There was a problem processing your request. Your connection has been reset. Please try again.',
            });
            props.authCx.reconnectWS();
          }

          throw new Error('Failed to create mstarget session event');
        }

        _sendMS(safeMsg, config.source);

        const hash = MiscHelper.hashify(config.msMsg);
        _setLastMSHash(hash);
        _setLastPitchID(config.pitch._id);

        if (config.pitch._id) {
          props.cookiesCx.setCookie(CookieKey.snapshot, {
            pitch_id: config.pitch._id,
          });
        }

        return {
          success: true,
          hash: hash,
        };
      } catch (e) {
        console.error(e);
        return {
          success: false,
        };
      }
    },

    toggleRuler(source, msg) {
      if (_checkActiveUser()) {
        NotifyHelper.success({
          message_md: `Toggling ruler for \`${_machine.machineID}\``,
        });
        WebSocketService.send(WsMsgType.U2S_Ruler, msg, source);
      }
    },

    adjustKeystone(source, msg) {
      if (_checkActiveUser()) {
        _specialMsTarget(SpecialMsPosition.home);
        WebSocketService.send(WsMsgType.U2S_Keystone, msg, source);
      }
    },

    getSpecialMode: () => _specialMode,
    setSpecialMode: (mode) => _setSpecialMode(mode),

    activateModel: async (config) => {
      const machinePayload: Partial<IMachine> = {
        _id: _machine._id,
        model_ids: _machine.model_ids ?? {},
      };

      if (machinePayload.model_ids) {
        machinePayload.model_ids[config.modelKey] = config.modelID;
      }

      const success = await _update(machinePayload);

      if (success) {
        MainService.getInstance().postSlack({
          type: 'info',
          msg: [
            'a model was activated',
            ` - machine: ${_machine.machineID}`,
            ` - model: \`${config.modelID}\``,
            ` - user: ${props.authCx.current.email}`,
            ` - role: ${props.authCx.current.role}`,
            ` - url: ${env.server_url}/main/model/${config.modelID}/html`,
          ].join('\n'),
        });

        const modelPayload: Partial<IMachineModel> = {
          _id: config.modelID,
          status: ModelStatus.Published,
        };

        AdminMachineModelsService.getInstance().updateModel(
          modelPayload,
          config.silently
        );
      }

      return success;
    },

    onEndTraining: () => {
      if (!_checkActiveUser(true)) {
        return;
      }

      if (!_checkFinishedFiring(true)) {
        return;
      }

      if (_machine.enable_auto_reset_ms) {
        _specialMsTarget(SpecialMsPosition.lowered);
        _setLastMSHash(undefined);
        return;
      }

      // only change the video + stop the wheels, leave the other aspects of the machine intact

      const msg: IMachineStateMsg = {
        ..._lastMS,
        w1: 0,
        w2: 0,
        w3: 0,
        video_uuid: DefaultVideoID.screensaver,
      };
      _sendMS(msg, 'training ended');
      _setLastMSHash(undefined);

      /** show toast asking user if they want to bring down the machine */
      NotifyHelper.warning({
        message_md: [
          'Your training session has ended.',
          'Would you like to lower the machine?',
        ].join('\n\n'),
        delay_ms: 10 * 1000,
        buttons: [
          {
            label: 'common.lower-machine',
            dismissAfterClick: true,
            onClick: () => _specialMsTarget(SpecialMsPosition.lowered),
          },
        ],
      });
    },

    playSound: (effect) => {
      if (!_checkActiveUser(true)) {
        return;
      }

      const data: IProjectorSfxMsg = {
        name: effect,
      };

      WebSocketService.send(
        WsMsgType.Misc_ProjectorSoundFx,
        data,
        CONTEXT_NAME
      );
    },

    getAutoFireButton: (config) => {
      switch (config.as) {
        case 'dialog-button': {
          return (
            <DialogButton
              className={config.className}
              color={_autoFire ? RADIX.COLOR.WARNING : RADIX.COLOR.NEUTRAL}
              onClick={() => {
                const newValue = !_autoFire;

                config.beforeToggleFn?.(newValue);

                _setAutoFire(newValue);

                SessionEventsService.postEvent({
                  category: 'machine',
                  tags: 'training',
                  data: {
                    event: 'toggle auto-fire',
                    auto: newValue,
                  },
                });
              }}
              label="common.auto"
              suffixIcon={
                <CustomIcon
                  icon={
                    _autoFire
                      ? CustomIconPath.SwitchOn
                      : CustomIconPath.SwitchOff
                  }
                />
              }
            />
          );
        }

        default: {
          return (
            <Button
              size={RADIX.BUTTON.SIZE.SM}
              className={config.className}
              color={_autoFire ? RADIX.COLOR.WARNING : RADIX.COLOR.NEUTRAL}
              onClick={() => {
                const newValue = !_autoFire;

                config.beforeToggleFn?.(newValue);

                _setAutoFire(newValue);

                SessionEventsService.postEvent({
                  category: 'machine',
                  tags: 'training',
                  data: {
                    event: 'toggle auto-fire',
                    auto: newValue,
                  },
                });
              }}
            >
              {t('common.auto')}
              &nbsp;
              <CustomIcon
                icon={
                  _autoFire ? CustomIconPath.SwitchOn : CustomIconPath.SwitchOff
                }
              />
            </Button>
          );
        }
      }
    },
  };

  const _initEmptyMachine = () => {
    _setMachine(DEFAULT.machine);
    _setCalibrated(false);
  };

  const _getIntercomURL = async (pitch_id: string | undefined) => {
    try {
      const data: IMSSnapshotData = {
        pitch_id: pitch_id,
        r2f: _lastR2F,
        last_ms: _lastMS,
      };

      const hash = MiscHelper.hashify(data);

      if (SUPPRESS_REDUNDANT_SNAPSHOTS && hash === _intercomHash) {
        // avoid recreating snapshots of basically identical payloads (excluding past_snapshot_ids)
        return;
      }

      // keep track of any previous snapshots for the session
      data.past_snapshot_ids = props.cookiesCx.snapshot.past_snapshot_ids;

      const result = await SessionEventsService.postEvent({
        category: 'machine',
        trigger: 'intercom',
        tags: 'snapshot',
        data: data,
      });

      if (!result.success) {
        throw new Error('Failed to create machine snapshot.');
      }

      const se = result.data as ISessionEvent;
      const url = `${env.server_url}/main/machine-snapshot/${se._id}/html?errors=10`;

      _setIntercomHash(hash);

      if (
        props.authCx.current.role === UserRole.admin ||
        props.authCx.current.mode === 'impostor'
      ) {
        NotifyHelper.debug(
          {
            message_md: 'Machine snapshot created!',
            buttons: [
              {
                label: 'Open URL',
                dismissAfterClick: true,
                onClick: () => window.open(url),
              },
            ],
          },
          props.cookiesCx
        );
      }

      return {
        event: se,
        url: url,
      };
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: `There was a problem creating your machine snapshot. See console for details.`,
      });
    }
  };

  const _updateIntercomURL = async (pitch_id: string | undefined) => {
    try {
      const result = await _getIntercomURL(pitch_id);
      if (!result) {
        return;
      }

      _setIntercomURL(result.url);

      // store the new id for use later
      props.cookiesCx.setCookie(CookieKey.app, {
        past_snapshot_ids: [
          ...props.cookiesCx.snapshot.past_snapshot_ids,
          result.event._id,
        ],
      });

      // create component to run the startup fn, don't draw to avoid flickering
      const ghost = <IntercomSnapshotUpdater url={result.url} />;

      NotifyHelper.debug(
        {
          message_md: 'Machine snapshot URL updated',
          buttons: [
            {
              label: 'Open',
              onClick: () => window.open(result.url),
              dismissAfterClick: true,
            },
          ],
        },
        props.cookiesCx
      );

      return ghost;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: `There was a problem updating your machine snapshot URL. See console for details.`,
      });
    }
  };

  /** fetch the data whenever machineID changes */
  useEffect(() => {
    if (!props.authCx.current.auth || !props.authCx.current.machineID) {
      _initEmptyMachine();
      return;
    }

    callback();

    async function callback() {
      const result = await MachinesService.getInstance().getByMachineID(
        props.authCx.current.machineID
      );

      if (!result) {
        NotifyHelper.warning({
          message_md: 'Session expired, please login and try again.',
        });

        /** suppress the usual logout message since this is automatically triggered */
        props.authCx.logout(true);
        return;
      }

      // reset hw warned flag whenever changing active machine
      if (_machine.machineID !== result.machine.machineID) {
        _setHwWarned(false);
      }

      _safeSetMachine(result.machine, result.calibrated);
    }
  }, [
    /** don't include props.authCx to avoid unnecessary re-triggers */
    _lastMachineEvent,
  ]);

  useEffect(() => {
    if (_recentlyFiredPitch && _recentlyFiredPitch._id) {
      setTimeout(() => {
        /** clear recently fired after a delay */
        _setRecentlyFiredPitch(undefined);
      }, RECENTLY_FIRED_DURATION);
    }
  }, [_recentlyFiredPitch]);

  useEffect(() => {
    if (props.authCx.current.auth) {
      MachinesService.getInstance()
        .getModelsDictionary()
        .then((result) => {
          _setModelDict(result);
        });
    } else {
      _setModelDict({});
    }
  }, [props.authCx.current.auth]);

  // start listening to ball status messages
  useEffect(() => {
    WebSocketHelper.on(WsMsgType.M2U_BallStatus, _handleBallStatus);
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, _handleReadyToFire);

    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_BallStatus, _handleBallStatus);
      WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, _handleReadyToFire);
    };
  }, []);

  // whenever ball count is updated (even if the number doesn't change)
  // run the logic to show / hide the inspect option and the notification toast
  useEffect(() => {
    if (_lastBallCount === undefined) {
      // we have no idea what's what yet
      return;
    }

    if (_lastBallCount === 1) {
      // nothing is wrong
      return;
    }

    if (_ballStatusToast) {
      // toast is already visible
      return;
    }

    switch (_specialMode) {
      case 'empty-carousel':
      case 'inspect-machine':
        return;

      default: {
        const buttons: INotificationButton[] = [
          {
            label: t('main.inspect'),
            onClick: () => setDialogInspect(Date.now()),
            dismissAfterClick: true,
          },
          {
            label: t('common.reset-position'),
            onClick: () => _specialMsTarget(SpecialMsPosition.home),
            dismissAfterClick: true,
          },
        ];

        switch (_lastBallCount) {
          case -1: {
            if (SUPPRESS_LOW_LIGHT_WARNING) {
              return;
            }

            _setBallStatusToast(true);
            NotifyHelper.warning({
              delay_ms: 0,
              header: 'common.lighting-error',
              message_md: t('common.lighting-error-msg'),
              buttons: buttons,
              onClose: () => _setBallStatusToast(false),
            });
            break;
          }

          case 0: {
            // no balls detected
            buttons.splice(0, 0, {
              label: t('main.drop-ball'),
              onClick: () => _dropball(true, 'ball-status toast'),
              dismissAfterClick: true,
            });

            _setBallStatusToast(true);
            NotifyHelper.warning({
              delay_ms: 0,
              header: 'common.no-ball-detected',
              message_md: t('common.no-ball-detected-msg'),
              buttons: buttons,
              onClose: () => _setBallStatusToast(false),
            });
            break;
          }

          default: {
            if (_lastBallCount > 1) {
              // multiple balls detected
              _setBallStatusToast(true);
              NotifyHelper.warning({
                delay_ms: 0,
                header: 'common.multiple-balls-detected',
                message_md: t('common.multiple-balls-detected-msg'),
                buttons: buttons,
                onClose: () => _setBallStatusToast(false),
              });
            }
            break;
          }
        }

        return;
      }
    }
  }, [_lastBallDate]);

  useEffect(() => {
    _getIntercomURL(props.cookiesCx.snapshot.pitch_id).then((result) => {
      if (!result) {
        NotifyHelper.debug(
          {
            message_md: 'Failed to create initial machine snapshot.',
          },
          props.cookiesCx
        );
        return;
      }

      _setIntercomURL(result.url);
    });
  }, []);

  return (
    <MachineContext.Provider value={state}>
      {_intercomURL && (
        <IntercomProvider
          appId={env.integrations.intercom_app_id}
          onShow={() => _updateIntercomURL(props.cookiesCx.snapshot.pitch_id)}
          autoBootProps={{
            email: props.authCx.current.email,
            userId: props.authCx.current.userID,
            customAttributes: {
              'Session ID': props.authCx.current.session,
              'Team ID': props.authCx.current.teamID,
              Team: props.authCx.current.team,
              League: props.authCx.current.league,
              Machine: props.authCx.current.machineID,
              'Machine Nickname':
                props.authCx.current.machineNickname ?? '(none)',
              'Machine Snapshots URL': _intercomURL,
            },
          }}
          autoBoot
        >
          <IntercomListener />
          {props.children}
          <MachineContext.Consumer>
            {(machineCx) => {
              return (
                <>
                  {dialogInspect && (
                    <MachineInspectionDialog
                      key={dialogInspect}
                      machineCx={machineCx}
                      identifier="MachineInspectionDialog"
                      onClose={() => setDialogInspect(undefined)}
                    />
                  )}

                  {dialogDisconnected && (
                    <MachineConnectionDialog
                      key={dialogDisconnected}
                      identifier="MachineConnectionDialog"
                      onClose={() => setDialogDisconnected(undefined)}
                    />
                  )}

                  {dialogRequestControl && (
                    <MachineControlDialog
                      key={dialogRequestControl}
                      identifier="MachineControlDialog"
                      onClose={() => setDialogRequestControl(undefined)}
                    />
                  )}

                  {dialogR2F && (
                    <R2FStatusDialog
                      key={dialogR2F}
                      identifier="R2FStatusDialog"
                      onClose={() => setDialogR2F(undefined)}
                    />
                  )}

                  {dialogRealign && (
                    <RealignMachineDialog
                      key={dialogRealign}
                      onClose={() => setDialogRealign(undefined)}
                    />
                  )}

                  <InboxContext.Consumer>
                    {(inboxCx) => (
                      <NotificationListener
                        cookiesCx={props.cookiesCx}
                        inboxCx={inboxCx}
                        machineCx={machineCx}
                      />
                    )}
                  </InboxContext.Consumer>
                </>
              );
            }}
          </MachineContext.Consumer>
        </IntercomProvider>
      )}
    </MachineContext.Provider>
  );
};
